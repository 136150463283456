.active {
    color: #0E7570;
    font-weight: 600;
    size: 16px;
    line-height: 19.36px;
    position: relative;
}

.active::after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: #0E7570;
    border-radius: 50%;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
}

@media (max-width: 640px) {
    .active::after {
        content: none;
    }
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}